import React, { useEffect } from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { Masonry } from 'masonic';
import { themeVal } from '@devseed-ui/theme-provider';

import useMediaAware from '../../utils/media-query-aware-hook';
import {
  MasonryItemsListElement,
  MasonryItemsWrapper,
  MasonryItemsWrapperInner
} from '../../styles/masonry';
import PostsListItem, { PostsListItemGhost } from './posts-list-item';

// TODO: Implement proper no data message styles
const NoDataMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20rem;
  background-color: ${themeVal('color.base-50a')};
  grid-column: 1 / -1;
`;

// TODO: Implement proper loading message styles
const LoadingMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20rem;
  background-color: ${themeVal('color.base-50a')};
  grid-column: 1 / -1;
`;

const masonryRenderFn = ({ index, data: post }) =>
  post.ghost ? (
    <PostsListItemGhost />
  ) : (
    <PostsListItem
      index={index}
      title={post.title}
      topics={post.topics}
      readTime={post.timeToRead}
      url={post.url}
      date={post.date}
      excerpt={post.excerpt}
      cardImage={post.card}
      authors={post.authors}
    />
  );

const PostsList = ({ hasError, isLoadingData, isLoadingPage, data }) => {
  const [ref, media] = useMediaAware();

  // In this case our reference is the body, and not a specific element.
  useEffect(() => {
    ref(document.body);
  }, [ref]);

  if (hasError || !data.results.length) {
    return (
      <NoDataMessage>
        <p>There is no data for the given filters</p>
      </NoDataMessage>
    );
  }

  if (isLoadingData) {
    return (
      <LoadingMessage>
        <p>Data is loading</p>
      </LoadingMessage>
    );
  }

  // Add three ghosts when more posts are loading.
  const items = isLoadingPage
    ? [
        ...data.results,
        { url: 'ghost1', ghost: true },
        { url: 'ghost2', ghost: true },
        { url: 'ghost3', ghost: true }
      ]
    : data.results;

  return (
    <MasonryItemsWrapper>
      <MasonryItemsWrapperInner>
        <Masonry
          items={items}
          columnGutter={
            media
              ? media.isXsmallDown()
                ? 16
                : media.isLargeDown()
                ? 32
                : 48
              : null
          }
          columnCount={
            media
              ? media.isSmallDown()
                ? 1
                : media.isMediumDown()
                ? 2
                : 3
              : null
          }
          columnWidth={288}
          as={MasonryItemsListElement}
          itemAs='li'
          itemKey={(post) => post.url}
          render={masonryRenderFn}
        />
      </MasonryItemsWrapperInner>
    </MasonryItemsWrapper>
  );
};

PostsList.propTypes = {
  hasError: T.bool,
  isLoadingData: T.bool,
  isLoadingPage: T.bool,
  data: T.object
};

export default PostsList;
