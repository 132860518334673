import React from 'react';
import T from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { themeVal } from '@devseed-ui/theme-provider';

import { Card } from '../../components/cards';
import { CardBlog } from '../../components/cards';

const bgC = themeVal('color.base-100a');
const bgD = themeVal('color.base-200a');

const placeHolderShimmer = keyframes`
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
`;

const gradientBg = () => css`
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeHolderShimmer};
  animation-timing-function: linear;
  background: ${bgC};
  background: linear-gradient(to right, ${bgC}, ${bgD}, ${bgC});
  background-size: 1000px;
`;

const GhostBlock = styled.span`
  ${gradientBg()}

  display: block;
  height: ${({ height = 1 }) => `${height}rem`};
  width: ${({ widthRatio = 1 }) => `${widthRatio * 100}%`};
  margin-bottom: ${({ spaceAfter = 0 }) => `${spaceAfter}rem`};
`;

const GhostAuthor = styled.div`
  display: flex;
  align-items: center;

  .img {
    ${gradientBg()}
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
  }

  .name {
    ${gradientBg()}
    height: 1rem;
    width: 10rem;
  }
`;

const PostsListItem = ({
  title,
  url,
  cardImage,
  readTime,
  authors,
  topics,
  date,
  excerpt,
  index
}) => (
  <CardBlog
    title={title}
    url={url}
    readTime={readTime}
    cardImage={cardImage}
    authors={authors}
    topics={topics}
    date={date}
    excerpt={excerpt}
    index={index + 1}
  />
);

PostsListItem.propTypes = {
  title: T.string,
  url: T.string,
  date: T.string,
  readTime: T.number,
  excerpt: T.string,
  cardImage: T.object,
  authors: T.arrayOf(
    T.shape({
      slug: T.string,
      name: T.string,
      avatar: T.object
    })
  ),
  topics: T.array,
  index: T.number
};

export default PostsListItem;

export const PostsListItemGhost = () => {
  return (
    <Card type='blog-post'>
      <div>
        <GhostBlock height={1} spaceAfter={1.5} widthRatio={1 / 3} />
        <GhostBlock height={3} spaceAfter={1.5} />
        <GhostBlock height={1} widthRatio={2 / 5} />
      </div>

      <div>
        <GhostBlock height={1.25} spaceAfter={0.5} />
        <GhostBlock height={1.25} spaceAfter={0.5} />
        <GhostBlock height={1.25} widthRatio={2 / 3} />
      </div>

      <GhostAuthor>
        <span className='img' />
        <span className='name' />
      </GhostAuthor>
    </Card>
  );
};
