import { useCallback, useReducer } from 'react';

import { fetchJSON } from '../../utils/utils';

// Loading data for the blog is a complex process not satisfied by a simple
// useState. There's a json file for each combination of topic and year, which
// is further divided into pages. The filename has the following format:
// `posts-${year}-${topic}-${page}.json`.
// We have to keep tack whether we're loading a entirely new year-topic
// combination or just a new page since these will affect the interface in
// different ways. For this we're using a reducer which is specifically tailored
// to suit the blog's needs.

const initialState = {
  fetchingData: false,
  fetchingPage: false,
  error: null,
  data: null
};

function reducer(state, action) {
  // If the action's timestamp is before the stored one, it means that it is a
  // previous action that was delayed and can be discarded.
  if (state.ts > action.ts) return state;

  switch (action.type) {
    case 'INVALIDATE':
      return initialState;
    case 'REQUEST_DATA': {
      return {
        ...initialState,
        fetchingData: true,
        ts: action.ts
      };
    }
    case 'REQUEST_PAGE': {
      return {
        ...state,
        fetchingPage: true,
        ts: action.ts
      };
    }
    case 'RECEIVE_DATA': {
      // eslint-disable-next-line prefer-const
      let st = {
        ...initialState,
        fetchingPage: false,
        fetchingData: false,
        ts: action.ts
      };

      if (action.error) {
        st.error = action.error;
      } else {
        st.data = action.data;
      }

      return st;
    }
    case 'RECEIVE_PAGE': {
      return {
        ...initialState,
        fetchingPage: false,
        fetchingData: false,
        ts: action.ts,
        data: {
          ...action.data,
          results: [...state.data.results, ...action.data.results]
        }
      };
    }
  }
  return state;
}

// Loading the data can never take less than the MIN_LOAD_TIME. This is needed
// to give the interface enough time to display the loading message. If the data
// loads too fast there will be a flicker on the screen leading to a subpar UX.
const MIN_LOAD_TIME = 512;

/**
 * Custom hook to request the blog data.
 * Returns a tuple with the state and the request function.
 * The signature of the request function is request(year, topic, page).
 *
 * @param {object} posts Initial data for the reducer.
 */
export function useBlogData(posts) {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    data: posts
  });

  const request = useCallback(
    async (topic, page) => {
      // Store the request timestamp.
      const ts = Date.now();
      // When loading the base data, dispatch immediately since we have it
      // already available.
      if (topic === 'all' && page === 1) {
        await wait(MIN_LOAD_TIME);
        return dispatch({ type: 'RECEIVE_DATA', data: posts, ts });
      }

      const reqAction = page === 1 ? 'REQUEST_DATA' : 'REQUEST_PAGE';
      dispatch({ type: reqAction, ts });
      try {
        const url = `/blog-posts-data/posts-${topic}-${page}.json`;
        const [body] = await Promise.all([
          fetchJSON(url).then(({ body }) => {
            // With the development server, gatsby returns {} for a non existent
            // json file. It is the not found version of a .json. Since for the
            // blog data we have a known structure, this check is sound.
            if (!body.meta) {
              const err = new Error('File not found');
              err.statusCode = 404;
              throw err;
            }
            return body;
          }),
          wait(MIN_LOAD_TIME)
        ]);
        // await new Promise(r => setTimeout(r, 5000));
        const recAction = page === 1 ? 'RECEIVE_DATA' : 'RECEIVE_PAGE';
        return dispatch({ type: recAction, data: body, ts });
      } catch (error) {
        return dispatch({ type: 'RECEIVE_DATA', error, ts });
      }
    },
    [posts]
  );

  return [state, request];
}

function wait(ms) {
  return new Promise((r) => setTimeout(r, ms));
}
