import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import get from 'lodash.get';
import { glsp, media, visuallyHidden } from '@devseed-ui/theme-provider';
import UniversalGridder from '../../styles/universal-gridder';

import Layout from '../../components/layout';
import Media from '../../components/media';
import {
  Inpage,
  InpageHeader,
  InpageHeaderInner,
  InpageHeadline,
  InpageTitle,
  InpageBody
} from '../../styles/inpage';
import { CardBlog } from '../../components/cards';
import TopicsNav from '../../components/topics-nav';
import { prepareAuthorProperties } from '../../utils/utils';
import MediaPlaceholderCover from '../../media/layout/placeholder-cover.jpg';
import PostsList from './posts-list';

import { useBlogData } from './reducer-actions';
import DataLoadButton from './data-load-button';

const Posts = styled(UniversalGridder).attrs({
  as: 'section',
  grid: {
    smallUp: ['full-start', 'full-end'],
    mediumUp: ['full-start', 'full-end'],
    largeUp: ['full-start', 'full-end']
  }
})`
  grid-column: 1 / -1;
  background: transparent;
`;

const Blog = ({ pageContext, location }) => {
  // Get the static values form the page context.
  // In this case the initial posts to render, the list of topics.
  const { posts, topics, featuredPost } = pageContext;

  const [page, setPage] = useState(1);

  const [state, request] = useBlogData(posts);

  // Request new data on filters change
  useEffect(() => {
    request('all', page);
  }, [page, request]);

  // Request new data on filters change

  // Static data comes from the static render, and is not loaded asynchronously.
  // This is the default state, with no filters and page 1.
  const isStaticData = page === 1;

  const postData = isStaticData ? posts : state.data;

  const hasError = !isStaticData && !!state.error;
  const isLoadingData = !isStaticData && state.fetchingData;
  const isLoadingPage = !isStaticData && state.fetchingPage;

  const hasMorePosts = postData
    ? postData.meta.total > postData.results.length
    : false;

  const pageTitle = 'Insights and musings from our team';
  return (
    <Layout location={location} title='Blog' description={pageTitle}>
      <Inpage as='section'>
        <InpageHeader>
          <InpageHeaderInner>
            <InpageHeadline>
              <InpageTitle>{pageTitle}</InpageTitle>
            </InpageHeadline>

            <TopicsNav topics={topics} base='/blog' />
          </InpageHeaderInner>
        </InpageHeader>
        <InpageBody isSoftPadded>
          <FeaturedPost post={featuredPost} />
          <Posts>
            <PostsList
              hasError={hasError}
              isLoadingData={isLoadingData}
              isLoadingPage={isLoadingPage}
              data={postData}
            />

            {!hasError && postData && (
              <DataLoadButton
                count={postData.results.length}
                total={postData.meta.total}
                hasMore={hasMorePosts}
                isLoading={isLoadingPage}
                onClick={() => setPage((p) => p + 1)}
              />
            )}
          </Posts>
        </InpageBody>
      </Inpage>
    </Layout>
  );
};

export default Blog;

Blog.propTypes = {
  pageContext: T.shape({
    featuredPost: T.object,
    posts: T.object,
    years: T.array,
    topics: T.array
  }),
  location: T.object
};

// // // // // // // // // // // // // // // // // // // // // // // // // // //
// FeaturedPost - Auxiliary components                                        //
//                                                                            //
const featuredPostSelfAttrs = (props) => ({
  as: 'section',
  grid: props.grid || {
    smallUp: ['full-start', 'full-end'],
    mediumUp: ['full-start', 'full-end'],
    largeUp: ['full-start', 'full-end']
  }
});
const FeaturedPostSelf = styled(UniversalGridder).attrs(featuredPostSelfAttrs)`
  grid-row-gap: 0;
  grid-column: 1 / -1;

  ${media.mediumUp`
    margin-bottom: ${glsp(-12)};
  `}

  ${media.largeUp`
    margin-bottom: ${glsp(-6)};
  `}
`;

const FeaturedPostHeader = styled.header`
  ${visuallyHidden()};
`;

const FeaturedPostBody = styled.div`
  position: relative;
  z-index: 20;
  grid-row: 2;
  grid-column: content-start / span content-end;
  margin-top: ${glsp(-4)};
  display: flex;
  justify-content: center;

  ${media.smallUp`
    margin-top: ${glsp(-6)};
  `}

  ${media.mediumUp`
    grid-column: 6 / span 4;
    margin-top: ${glsp(-20)};
  `}

  ${media.largeUp`
    grid-column: 10 / span 4;
    margin-top: ${glsp(-28)};
  `}

  > * {
    width: 100%;
    max-width: 28rem;

    ${media.mediumUp`
      max-width: none;
    `}
  }
`;

const FeaturedPostMedia = styled(Media)`
  grid-row: 1;
  grid-column: 1 / -1;
`;

const FeaturedPost = (props) => {
  const { post } = props;
  if (!post) return null;

  const { teaser, title, authors, topics, media } = post.frontmatter;
  const { slug, timeToRead, date } = post;

  const authorsFields = prepareAuthorProperties(authors);

  const coverImage = get(media, 'cover.url.childImageSharp.fluid');
  const coverImageAttribution = get(media, 'cover.attribution');

  return (
    <FeaturedPostSelf>
      <FeaturedPostHeader>
        <h1>Featured post</h1>
      </FeaturedPostHeader>
      <FeaturedPostBody>
        <CardBlog
          title={title}
          slug={slug}
          readTime={timeToRead}
          authors={authorsFields}
          topics={topics}
          date={date}
          excerpt={teaser || post.excerpt}
        />
      </FeaturedPostBody>
      <FeaturedPostMedia
        size='cover'
        decoration='none'
        src={!coverImage ? MediaPlaceholderCover : ''}
        fluid={coverImage}
        alt='Featured post cover image'
        attribution={coverImageAttribution}
      />
    </FeaturedPostSelf>
  );
};

FeaturedPost.propTypes = {
  post: T.object
};

//                                                                            //
// END   FeaturedPost - Auxiliary components                                  //
// // // // // // // // // // // // // // // // // // // // // // // // // // //
